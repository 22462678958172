import React, { useState } from 'react';
import PropTypes from 'prop-types';

const MessageModal = ({ isActive: initActive = false, dataString, subjectString, type = ''}) => {
  const [isActive, setIsActive] = useState(initActive);
  const modalClassnames = ['modal'];

  if (isActive) {
    modalClassnames.push('active');
  }

  return (
    <div className='circle'>
      <button
        className='circle circle-email'
        onClick={() => {
          setIsActive(true);
          window.location.href = `mailto:?subject=${subjectString}&body=${dataString}`;

          if (type === 'food') {
            emailFoodList();
          }
          else {
            emailQuestions();
          }
        }}
        type='button'
      />
      <div className={modalClassnames.join(' ')} >
        {isActive && (
          <div className='message-container'>
            <span className='close-button' onClick={() => setIsActive(false)} />
            <p>This should be opening your mail app on your phone/computer. </p>
            <p>
              If you’re having  troubles, select the “print” button on your web
              browser, select “Save as PDF” and attach the file to your email message.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageModal;

MessageModal.propTypes = {
  isActive: PropTypes.bool,
  dataString: PropTypes.string.isRequired,
  subjectString: PropTypes.string.isRequired,
  type: PropTypes.string,
};
