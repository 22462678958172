import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import filter from 'lodash/filter';
import { useCookies } from 'react-cookie';
import { CSSTransition } from 'react-transition-group';
import { QUESTIONS, SYMPTOMS } from '../constants';
import { withMyFoodList } from '../Root';
import EmailButton from '../email/Questions';
import PrintButton from '../PrintButton';

const symptomsNames = SYMPTOMS.map(s => s.id);

const List = ({ pageId = null }) => {
  const [cookies, setCookie] = useCookies(['QUESTIONS']);
  const myQuestionList = cookies.QUESTIONS || [];
  const spanRef = useRef(null);

  const [animate, setAnimate] = useState(false);
  const questionCount = myQuestionList.length;

  useEffect(() => {
    setAnimate(true);
  }, [questionCount]);

  const filteredQuestions = filter(QUESTIONS, q => {
    if (pageId) {
      return q.pages.indexOf(pageId) >= 0;
    }

    return myQuestionList.indexOf(q.id) >= 0;
  });

  if (filteredQuestions && filteredQuestions.length) {
    return (
      <section>
        <div className='question-list'>
          {pageId && (
            <div className='question-list-title'>
              <h3>What questions should I ask my healthcare provider?</h3>
              <a className='food-list-button' href='/healthcare-provider-questions/view-my-questions'>
                <span className='my-question-list-toggle'>
                  View <strong>My Question List</strong>
                  <CSSTransition
                    nodeRef={spanRef}
                    classNames={{
                      appear: 'count',
                      enter: 'animated bounce'
                    }}
                    in={animate}
                    onEntered={() => {
                      setAnimate(false);
                    }}
                    timeout={1000}
                  >
                    <span ref={spanRef} className='count'>{questionCount}</span>
                  </CSSTransition>
                </span>
              </a>
            </div>
          )}

          <div className='email-print-wrap'>
            <EmailButton />
            <PrintButton type='food' />
          </div>

          {filteredQuestions.map(({ id, question }) => {
            const questionIndex = myQuestionList.indexOf(id);
            const isChecked = myQuestionList.indexOf(id) >= 0;
            return (
              <div className='question-item' key={id}>
                <p>{question}</p>
                <label>
                  <input
                    checked={isChecked}
                    onChange={() => {
                      let newList = [];

                      if (isChecked) {
                        newList = [
                          ...myQuestionList.slice(0, questionIndex),
                          ...myQuestionList.slice(questionIndex + 1)
                        ];
                      }
                      else {
                        newList = [...myQuestionList, id];
                        catchQuestion(question.trim()); // eslint-disable-line
                      }

                      setCookie('QUESTIONS', newList, { path: '/' });
                    }}
                    type='checkbox'
                    value={id}
                  />
                  <span>
                    {isChecked ? 'Remove from' : 'Add to'} My Questions List
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      </section>
    );
  }
  else if (symptomsNames.includes(pageId)) {
    return <div />;
  }

  return (
    <h5>
      Your question list is empty, add some questions. A list of common questions for your HCP are
      located at the bottom of most My Kidneys My Health web pages.</h5>
  );
};

export default withMyFoodList(List);

List.propTypes = {
  pageId: PropTypes.string
};