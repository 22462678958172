import React from 'react';
import PropTypes from 'prop-types';
import Item from './Item';

const List = ({ data, showImage = false}) => {
  if (data.length > 0) {
    return (
      <section className='food-list'>
        {data.map(food => (
          <Item key={food.id} showImage={showImage} {...food} />
        ))}
      </section>
    );
  }
  // eslint-disable-next-line no-else-return
  else {
    return (
      <div>
        <h3>
          Your search did not match any foods in the guide. This is a database
          of common individual whole foods.
        </h3>
        <h4>Suggestions:</h4>
        <ul>
          <li>Make sure that all words are spelled correctly</li>
          <li>Try different keywords</li>
          <li>Try a more general name of the food</li>
        </ul>
      </div>
    );
  }
};

export default List;

List.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  showImage: PropTypes.bool,
};