import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MineralMetre from './MineralMetre';
import MyListToggle from './MyListToggle';

const FOOD_IMAGES = {};

const importAll = r => {
  r.keys().forEach(key => {
    const result = key.match(/\/([^./]+)\./);

    if (result) {
      FOOD_IMAGES[result[1]] = r(key);
    }
  });
};

importAll(require.context('../../images', true, /\.jpg$/)); // eslint-disable-line

const Item = props => {
  const { id, name, portion, serving, warning = false, message = '', showImage = false} = props;
  const [show, setShow] = useState(warning);
  const warningClasses = ['warning-overlay'];

  if (show) {
    warningClasses.push('show');
  }

  return (
    <figure key={id}>
      <div className={['image-wrapper', showImage && 'has-image'].join(' ')}>
        {showImage && <img alt={id} src={FOOD_IMAGES[id]} />}
        <span className='serving'>
          <span className={`portion ${portion}`} />
          <span className='size'>{serving}</span>
        </span>
      </div>
      <figcaption>
        <h5>
          {warning && (
            <button
              onClick={() => {
                setShow(true);
              }}
              type='button'
            >
              <img alt='Warning' src='/warning.png' />
            </button>
          )}
          {name}
        </h5>
        <div className='item-content'>
          <div className={warningClasses.join(' ')}>
            {message}
            <button
              type='button'
              onClick={() => {
                setShow(false);
              }}
            >
              Okay
            </button>
          </div>
          <MineralMetre {...props} />
          <MyListToggle id={id} name={name} />
        </div>
      </figcaption>
    </figure>
  );
};

export default Item;

Item.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  portion: PropTypes.string.isRequired,
  serving: PropTypes.string.isRequired,
  message: PropTypes.string,
  warning: PropTypes.bool,
  showImage: PropTypes.bool,
};