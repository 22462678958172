import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ImageModal = ({ isActive: initActive = false, src, alt }) => {
  const [isActive, setIsActive] = useState(initActive);
  const modalClassnames = ['modal'];

  if (isActive) {
    modalClassnames.push('active');
  }

  return (
    <div>
      <button onClick={() => setIsActive(true)} type='button'>
        <img alt={alt} src={`../${src}`} />
        <span className='magnify'>
          <img alt='Zoom' src='../magnify.png' />
        </span>
      </button>
      <div
        className={modalClassnames.join(' ')}
        onClick={() => setIsActive(false)}
      >
        {isActive && (
          <div className='image-wrapper'>
            <img alt={alt} src={`../${src}`} />
          </div>
        )}
        <span className='close-button' />
      </div>
    </div>
  );
};

export default ImageModal;

ImageModal.propTypes = {
  isActive: PropTypes.bool,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};