import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const SymptomsModal = ({ isActive: initActive = false, id, name, info }) => {
  const [isActive, setIsActive] = useState(initActive);
  const modalClassnames = ['modal'];

  if (isActive) {
    modalClassnames.push('active');
  }

  useEffect(() => {
    const handleHashChange = () => {
      setIsActive(window.location.href.includes(`#${id}`));
    };

    window.addEventListener('hashchange', handleHashChange);

    if (isActive) {
      catchModal(`${document.location.pathname}#${id}`, name); // eslint-disable-line
    }

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  });

  const openModal = () => {
    window.location.hash = `#${id}`;
  };

  const closeModal = () => {
    window.location.hash = 'all';
  };

  return (
    <div className='symptoms-item'>
      <button key={id} onClick={() => openModal()} type='button'>
        <img src={`/symptoms_icons/${id}.png`} />
        <h5>{name}</h5>
        <hr />
        <p>
          <img src='/info.png' />
          <span>See Details</span>
        </p>
      </button>

      <div
        className={modalClassnames.join(' ')}
        // onClick={() => setIsActive(false)}
      >
        <div className='info-container'>
          <span className='close-button' onClick={() => closeModal()} />
          <h2>{name}</h2>
          <div className='symptoms-md' dangerouslySetInnerHTML={{ __html: info}} />
        </div>
      </div>
    </div>
  );
};

export default SymptomsModal;

SymptomsModal.propTypes = {
  id: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  name: PropTypes.string.isRequired
};